import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import { useIntl } from 'gatsby-plugin-react-intl';

import Checkout from 'components/Checkout';

export default function CheckoutPage(props) {
    let {
        data: {
            allShopifyProduct: { nodes: products },
            allContentfulProduct: { nodes: contentfulProducts },
        },
        location,
    } = props;

    return (
        <Layout>
            <Checkout
                shopifyProducts={products}
                contentfulProducts={contentfulProducts}
                location={location}
            />
        </Layout>
    );
}

export const query = graphql`
    query {
        allShopifyProduct {
            nodes {
                ...ShopifyProductFields
            }
        }
        allContentfulProduct {
            nodes {
                ...ContentfulProductFields
            }
        }
    }
`;
