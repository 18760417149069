import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby-link';
import urls from 'helpers/url';
import { injectIntl } from 'gatsby-plugin-react-intl';
import get from 'lodash.get';
import AuthContext from '../provider/AuthContext';

import {
    Spacing,
    SharedStyles,
    Container,
    Fieldset,
    FieldsGroup,
    FieldInput,
    FieldTextarea,
    FieldSubmit,
    CheckoutDetail,
    QuantityButtons,
} from '../kenra-storybook/index';
import product from 'templates/product';

const {
    StBackArrow,
    StBackArrowText,
    StTitleText,
    StProductImage,
    StCheckoutProduct,
    StCheckoutProductText,
    StCheckoutProductTextTitle,
    StCheckoutProductTextSubtitle,
    StShoppingList,
    StSummary,
    StSummaryTitle,
    StSummaryItemsText,
    StSummaryItemsPrice,
    StSummaryTotalText,
    StSummaryTotalPrice,
    StSummarySeperator,
    StCheckout,
    StPrice,
    StProductSeperator,
    StTaxText,
    StDeleteButton,
    StCheckoutSpacer,
} = CheckoutDetail;

const { StLinkMore, StSectionInfo, StTitle } = SharedStyles;

const Checkout = ({ intl, location }) => {
    const [state, setState] = React.useState({
        isInitialData: true,
        isRefreshData: false,
        refreshData: {},
        productState: {},
    });

    let context = React.useContext(AuthContext);

    useEffect(() => {
        if (context.startCheckout) {
            checkout(context);
            context.toggleCheckout();
        }
    }, [context.startCheckout, context]);

    const continueShopping = async function(updateCart) {
        let dataToUpdate = [];
        state.productState.products.forEach(product => {
            if (product.quantity !== product.initialQuantity) {
                let itemToUpdate = {
                    entryNumber: product.entryNumber,
                    quantity: product.quantity,
                };
                dataToUpdate.push(itemToUpdate);
            }
        });

        let result = true;
        for (const item of dataToUpdate) {
            result = await updateCart(item);
        }

        if (result) {
            navigate(-1);
        }
    };

    const checkout = async function(context) {
        // figure out the difference between what we have and what's now needed
        let dataToUpdate = [];
        state.productState.products.forEach(product => {
            if (product.quantity !== product.initialQuantity) {
                let itemToUpdate = {
                    entryNumber: product.entryNumber,
                    quantity: product.quantity,
                };
                dataToUpdate.push(itemToUpdate);
            }
        });

        for (const item of dataToUpdate) {
            await context.updateCart(item);
        }

        let result = await context.checkout();
        if (result) {
            // Show info
            context.toggleShowInfo();
        }
    };

    const handleDelete = async (
        entryNumber,
        deleteFromCart,
        updateShoppingCartQuantity
    ) => {
        let body = {
            entryNumber: entryNumber,
        };
        let updatedCart = await deleteFromCart(body);
        if (updatedCart) {
            // set the refresh data and set the refreshNeeded
            setState({ refreshData: updatedCart, isRefreshData: true });
            updateShoppingCartQuantity(updatedCart.products.length);
        } else {
            console.log('NOT DELETED');
        }
    };

    const handleChange = (event, entryNumber, updateQuantity) => {
        let productState = state.productState;
        productState.products[entryNumber].quantity = event.target.value;
        setState({ productState: productState });
        updateQuantity(state.productState.products[entryNumber].quantity);
    };

    const handleQuantityPlus = (entryNumber, updateQuantity) => {
        let productState = state.productState;
        productState.products[entryNumber].quantity += 1;
        setState({ productState: productState });
        updateQuantity(productState.products[entryNumber].quantity);
    };

    const handleQuantityMinus = (entryNumber, updateQuantity) => {
        let productState = state.productState;
        if (productState.products[entryNumber].quantity > 0) {
            productState.products[entryNumber].quantity -= 1;
        }
        setState({ productState: productState });
        updateQuantity(productState.products[entryNumber].quantity);
    };

    let cartData = null;
    let cartState = {
        products: [],
        subTotal: '$0.00',
        shipping: '$0.00',
        totalPrice: '$0.00',
    };
    if (
        typeof location !== 'undefined' &&
        typeof location.state !== 'undefined' &&
        state.isInitialData
    ) {
        cartData = location.state.cartData;
        if (typeof cartData !== 'undefined') {
            cartState.products = cartData.products.map((product, index) => ({
                image: product.image,
                title: product.title,
                idh: product.idh,
                quantity: product.quantity,
                initialQuantity: product.quantity,
                price: product.price,
                entryNumber: index,
            }));

            cartState.userToken = cartData.userToken;
            if (typeof cartData.subTotal !== 'undefined') {
                cartState.subTotal = cartData.subTotal;
            }
            if (typeof cartData.shipping !== 'undefined') {
                cartState.shipping = cartData.shipping;
            }
            if (typeof cartData.totalPrice !== 'undefined') {
                cartState.totalPrice = cartData.totalPrice;
            }
        }
        setState({ productState: cartState, isInitialData: false });
    } else if (state.isRefreshData) {
        cartData = state.refreshData;
        if (typeof cartData !== 'undefined') {
            cartState.products = cartData.products.map((product, index) => ({
                image: product.image,
                title: product.title,
                idh: product.idh,
                quantity: product.quantity,
                initialQuantity: product.quantity,
                price: product.price,
                entryNumber: index,
            }));
            cartState.userToken = cartData.userToken;
            if (typeof cartData.subTotal !== 'undefined') {
                cartState.subTotal = cartData.subTotal;
            }
            if (typeof cartData.shipping !== 'undefined') {
                cartState.shipping = cartData.shipping;
            }
            if (typeof cartData.totalPrice !== 'undefined') {
                cartState.totalPrice = cartData.totalPrice;
            }
        }
        setState({ productState: cartState, isRefreshData: false });
    } else {
        cartData = state.productState;
    }

    let checkoutData = state.productState;
    return (
        <AuthContext.Consumer>
            {auth => (
                <>
                    <Spacing>
                        <Container>
                            <StBackArrow
                                onClick={() =>
                                    continueShopping(auth.updateCart)
                                }
                            >
                                <img src={'/images/back-arrow.png'} />
                                <StBackArrowText>
                                    {intl.formatMessage({
                                        id:
                                            'salonory-checkout-continue-shopping',
                                    })}
                                </StBackArrowText>
                            </StBackArrow>
                            <StCheckout>
                                <StShoppingList>
                                    <StTitleText>
                                        {intl.formatMessage({
                                            id:
                                                'salonory-checkout-shopping-bag',
                                        })}
                                    </StTitleText>
                                    {checkoutData &&
                                        checkoutData.products &&
                                        checkoutData.products.map(
                                            (product, index) => (
                                                <>
                                                    <StCheckoutProduct
                                                        key={index}
                                                    >
                                                        <StProductImage
                                                            style={{
                                                                backgroundImage: `url(${product.image})`,
                                                            }}
                                                        />
                                                        <StCheckoutProductText>
                                                            <StCheckoutProductTextTitle>
                                                                {product.title}
                                                            </StCheckoutProductTextTitle>
                                                            <StCheckoutProductTextSubtitle>
                                                                {'IDH # ' +
                                                                    product.idh}
                                                            </StCheckoutProductTextSubtitle>
                                                        </StCheckoutProductText>
                                                        <QuantityButtons
                                                            handleChange={(
                                                                e,
                                                                updateQuantity
                                                            ) =>
                                                                handleChange(
                                                                    e,
                                                                    product.entryNumber,
                                                                    updateQuantity
                                                                )
                                                            }
                                                            handleQuantityMinus={updateQuantity =>
                                                                handleQuantityMinus(
                                                                    product.entryNumber,
                                                                    updateQuantity
                                                                )
                                                            }
                                                            handleQuantityPlus={updateQuantity =>
                                                                handleQuantityPlus(
                                                                    product.entryNumber,
                                                                    updateQuantity
                                                                )
                                                            }
                                                            quantity={
                                                                product.quantity
                                                            }
                                                            reduceSize
                                                        />
                                                        <StPrice>
                                                            {product.price}
                                                        </StPrice>
                                                        <StDeleteButton
                                                            onClick={() =>
                                                                handleDelete(
                                                                    product.entryNumber,
                                                                    auth.deleteFromCart,
                                                                    auth.updateShoppingCartQuantity
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    '/images/trash.png'
                                                                }
                                                            />
                                                        </StDeleteButton>
                                                    </StCheckoutProduct>
                                                    <StProductSeperator />
                                                </>
                                            )
                                        )}
                                    <StTaxText>
                                        {intl.formatMessage({
                                            id: 'salonory-checkout-tax-text',
                                        })}
                                    </StTaxText>
                                </StShoppingList>
                                <StSummary>
                                    <StSummaryTitle>
                                        {intl.formatMessage({
                                            id:
                                                'salonory-checkout-order-summary',
                                        })}
                                    </StSummaryTitle>
                                    <StCheckoutSpacer>
                                        <StSummaryItemsText>
                                            {intl.formatMessage({
                                                id:
                                                    'salonory-checkout-total-text',
                                            })}
                                        </StSummaryItemsText>
                                        <StSummaryItemsPrice>
                                            {checkoutData &&
                                                checkoutData.subTotal}
                                        </StSummaryItemsPrice>
                                    </StCheckoutSpacer>
                                    <StCheckoutSpacer>
                                        <StSummaryItemsText>
                                            {intl.formatMessage({
                                                id:
                                                    'salonory-checkout-shipping-text',
                                            })}
                                        </StSummaryItemsText>
                                        <StSummaryItemsPrice>
                                            {checkoutData &&
                                                checkoutData.shipping}
                                        </StSummaryItemsPrice>
                                    </StCheckoutSpacer>
                                    <StSummarySeperator />
                                    <StCheckoutSpacer>
                                        <StSummaryTotalText>
                                            {intl.formatMessage({
                                                id:
                                                    'salonory-checkout-order-total-text',
                                            })}
                                        </StSummaryTotalText>
                                        <StSummaryTotalPrice>
                                            {checkoutData &&
                                                checkoutData.totalPrice}
                                        </StSummaryTotalPrice>
                                    </StCheckoutSpacer>
                                    <StLinkMore>
                                        <a
                                            onClick={() =>
                                                auth.toggleCheckout()
                                            }
                                        >
                                            {intl.formatMessage({
                                                id:
                                                    'salonory-checkout-checkout-text',
                                            })}
                                        </a>
                                    </StLinkMore>
                                </StSummary>
                            </StCheckout>
                        </Container>
                    </Spacing>
                    <form
                        id="checkout-form"
                        action={
                            process.env.GATSBY_SALONORY_ACCOUNT +
                            '/en_US/authentication/redirect'
                        }
                        method="POST"
                        target="_blank"
                    >
                        <input
                            type="hidden"
                            value={checkoutData && checkoutData.userToken}
                            name="access_token"
                            id="access_token"
                        />
                        <input
                            type="hidden"
                            value="/cart"
                            name="path"
                            id="path"
                        />
                        <input
                            type="hidden"
                            value={intl.formatMessage({
                                id: 'salonory-checkout-redirect-button-text',
                            })}
                            name="buttonLabel"
                            id="buttonLabel"
                        />
                        <input
                            type="hidden"
                            value="https://kenraprofessional.com"
                            name="returnUrl"
                            id="returnUrl"
                        />
                    </form>
                </>
            )}
        </AuthContext.Consumer>
    );
};

export default injectIntl(Checkout);
